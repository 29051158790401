/* Variables */
$grid-columns: 12 !default;
$grid-break-md: 640px !default;
$grid-break-lg: 960px !default;
$grid-break-xl: 1150px !default;

/* Mixins */
@mixin media-sm {
  @media only screen and (max-width: #{$grid-break-md - 1}) {
    @content;
  }
}

@mixin media-md {
  @media only screen and (min-width: #{$grid-break-md}) {
    @content;
  }
}

@mixin media-lg {
  @media only screen and (min-width: #{$grid-break-lg}) {
    @content;
  }
}

@mixin media-xl {
  @media only screen and (min-width: #{$grid-break-xl}) {
    @content;
  }
}

@mixin grid-row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
}

@mixin grid-col {
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: column;
  flex-shrink: 0;
}

@mixin grid-col-width($width) {
  $width-percent: percentage($width / $grid-columns);
  flex-basis: $width-percent;
  max-width: $width-percent;
}

/* Classes ================================================= */

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (min-width: $grid-break-md) {
    width: 100%;
  }

  @media only screen and (min-width: $grid-break-lg) {
    width: 100%;
    max-width: 1200px;
  }
}

.row {
  padding: 0 24px;
  @include grid-row;
}

.col {
  @include grid-col;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

/* col-sm */
@for $i from 1 through $grid-columns {
  .col-sm-#{$i} {
    @include grid-col;
    @include grid-col-width($i);
  }
}

/* col-md */
@include media-md {
  @for $i from 1 through $grid-columns {
    .col-md-#{$i} {
      @include grid-col;
      @include grid-col-width($i);
    }
  }
}

/* col-lg */
@include media-lg {
  @for $i from 1 through $grid-columns {
    .col-lg-#{$i} {
      @include grid-col;
      @include grid-col-width($i);
    }
  }
}

/* col-lg */
@include media-xl {
  @for $i from 1 through $grid-columns {
    .col-xl-#{$i} {
      @include grid-col;
      @include grid-col-width($i);
    }
  }
}
