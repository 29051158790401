@import '../../colors';

.verificationContainer {
  .verificationSection {
    padding: 128px 0;

    .title, .subTitle {
      font-family: "Source Sans Pro", sans-serif;
      margin: 0;
      &.success {
        color: map-get($colors, primary);
      }
      &.error {
        color: map-get($colors, warning);
      }
    }
    .title {
      font-weight: 600;
      font-size: 2.8rem;
    }

    .subTitle {
      font-weight: 400;
      font-size: 1.8rem;
      color: #444;
    }

  }
}

