.parallaxContainer {
  width: 100%;
  position: relative;

  .parallaxImage {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.3s ease;
    &.visible {
      opacity: 1;
    }

    .shader {
      width: 100%;
      height: 100%;
      &.visible {
        background-color: rgba(0, 0, 0, .7);
      }
    }
  }
}
