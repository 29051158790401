@import '../../colors';

.newsContainer {
  .article {
    display: flex;
    padding: 32px;
    flex-direction: column;
    .imageContainer {
      overflow: hidden;
      width: 100%;
      height: 188px;
      .image {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        transition: transform .3s ease;
        cursor: pointer;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
    .title {
      font-size: 18px;
      color: #0f3740;
      line-height: 25px;
      text-align: left;
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 300;
      font-style: normal;
      margin: 0;
      padding: 8px 0 2px 0;
    }
    .timestamp {
      font-size: 16px;
      color: #1abc9c;
      text-align: left;
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 400;
      font-style: normal;
      margin: 0;
    }
    .storyContainer {
      p {
        color: #7e868e;
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 300;
        margin: 8px 0;
      }
    }

    .storyLink {
      margin: 0;
      a {
        color: map-get($colors, primary);
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 400;
        margin: 8px 0;
        text-decoration: none;
      }
    }
  }
}

