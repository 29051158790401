@import '../../colors';

.section {
  padding: 64px 0;
  &.no-padding {
    padding: 0;
  }
  &.no-padding-bottom {
    padding-bottom: 0;
  }
}

.sectionTitleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 0 32px 0;
  position: relative;
  &.no-padding {
    padding: 0;
  }
  &.no-padding-bottom {
    padding-bottom: 0;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 70%;
    background-color: map-get($coolGray, c2);
    top: 70px;

  }
  .title {
    color: #1abc9c;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin: 0;
  }
  .subTitle {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 24px;
    text-align: center;
    font-weight: 400;
    padding-bottom: 24px;
    margin: 0;
    position: relative;
  }
}
