@import '../../colors';

.downloadComponent {
  background-color: map-get($colors, primary);
  padding: 32px 0;
  z-index: 100;
  .downloadPayOff {
    letter-spacing: 0;
    font-family: "Source Sans Pro", sans-serif;
    color: #fff;
    font-size: 28px;
    font-weight: 400;
    text-align: center;
  }
  .storeButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .storeButton {
      height: 50px;
      margin: 2px;
      cursor: pointer;
      -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      transition: all .3s ease;
      &:hover {
        transform: translate(0, -4px);
        -webkit-box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
  .socialContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .socialIcon {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 4px;
      transition: all .3s ease;
      cursor: pointer;
      &:hover {
        transform: translate(0, -4px);
        -webkit-box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
}
