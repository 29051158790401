@import '../../colors';

.testimonialComponent {
  background-color: #032d37;
  padding: 64px 0;
  .testimonialHeader {
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: "Source Sans Pro", sans-serif;
    color: map-get($colors, primary);
    text-align: center;
    margin: 0 0 16px 0;
  }

  .testimonial {
    user-select: none;
    cursor: pointer;
    .quote {
      padding: 0 25px;
      margin: 0 auto;
      color: #fff;
      font-family: "Source Sans Pro", sans-serif;
      font-size: 23px;
      font-weight: 400;
      letter-spacing: -1px;
      line-height: 31px;
      text-align: center;
    }
    .story {
      padding: 15px 0;
      p {
        font-family: "Source Sans Pro", sans-serif;
        font-size: 18px;
        line-height: 25px;
        font-weight: 400;
        color: #fff;
        opacity: 0.75;
        text-align: center;
      }
    }
    .author {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 18px;
      line-height: 23px;
      font-weight: 400;
      padding: 15px 0;
      color: #7e868e;
      text-align: center;
    }
  }
}
