.buttonContainer {
  display: flex;
  flex: 0 0 auto;
  cursor: pointer;
  padding: 6px 8px;
  width: auto;
  border-radius: 2px;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  transition: all .3s ease;
  height: 30px;
  align-items: center;
  margin: 4px;
  &.disabled {
    opacity: .1;
  }
  .buttonText {
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 31px;
    text-align: center;
    margin: 0;
  }
  &:hover {
    transform: translate(0, -5px);
    -webkit-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
  }

  .buttonIconLeft, .buttonIconRight {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .buttonIconLeft {
    margin: 0 8px 0 0;
  }
  .buttonIconRight {
    margin: 0 0 0 8px;
  }
}
