@import '../../colors';

.footerComponent {
  .contactSection {
    background-color: #262626;
    padding: 64px 0;
    .footerCard {
      padding: 16px;
      .title {
        font-size: 1.5rem;
        margin: 0 0 8px 0;
        color: map-get($colors, primary);
        text-transform: uppercase;
        font-weight: 600;
      }
      .footerParagraph {
        font-size: 1rem;
        color: #fff;
        line-height: 1.3rem;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        &.footerLink {
          cursor: pointer;
          transition: color .3s ease;
          &:hover {
            color: map-get($colors, primary);
          }
        }
        .footerIcon {
          padding: 4px 8px 4px 0;
        }
      }
    }
  }
  .copyrightSection {
    background-color: #17191c;
    padding: 32px 0;
    .logo {
      max-height: 30px;
    }
    .copyrightLink {
      color: map-get($colors, primary);
      border-right: 1px solid #fff;
      padding: 0 8px;
      font-size: 14px;
      font-family: "Source Sans Pro", sans-serif;
      letter-spacing: 0;
      font-weight: 400;
      text-decoration: none;
      display: inline-block;
      cursor: pointer;
      &:last-child {
        border-right: none;
      }
    }
  }
}
