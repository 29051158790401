@import '../../colors';

.storeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  flex-direction: column;

  .storeButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .storeButton {
      height: 50px;
      margin: 2px;
      cursor: pointer;
      -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      transition: all .3s ease;
      &:hover {
        transform: translate(0, -4px);
        -webkit-box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
}
