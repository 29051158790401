@import '../../colors.scss';

.jobsContainer {
  .jobContainer {
    display: flex;
    flex-direction: column;
    padding: 0 32px;
    align-items: flex-start;
    .jobOfferIcon {
      padding: 0 0 8px 0;
    }
    .jobOfferHeader {
      font-family: 'Source Sans Pro', sans-serif;
      letter-spacing: 0;
      font-size: 16px;
      font-weight: 400;
      padding: 5px 0;
      line-height: 16px;
      color: map-get($colors, primary);
      margin: 0;
    }
    .jobOfferTitle {
      font-family: 'Source Sans Pro', sans-serif;
      letter-spacing: 0;
      font-size: 23px;
      font-weight: 400;
      color: #525459;
      line-height: 31px;
      margin: 0;
    }
    .jobOfferIntro {
      p {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
        line-height: 23px;
        font-weight: 400;
        padding: 0 0 16px 0;
        color: #7e868e;
        margin: 0;
      }
    }
  }
}
