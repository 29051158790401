@import '../../colors';

$grid-break-md: 640px !default;

.homeContainer {
  .parallaxContent {
    padding-top: 100px;

    .payOffContainer {
      display: flex;
      justify-content: center;
      opacity: 0;
      transition: opacity .5s ease;
      @media only screen and (max-width: $grid-break-md) {
        padding: 32px 32px 32px 32px;
      }

      &.visible {
        opacity: 1;
      }

      .title {
        font-weight: 600 !important;
        font-family: "Source Sans Pro", sans-serif;
        letter-spacing: 0;
        text-transform: uppercase;
        margin: 0;
        color: #fff;
      }

      .text {
        font-family: "Source Sans Pro", sans-serif;
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        color: #fff !important;
        line-height: 24px;
      }
    }

    .appPreviewContainer {
      display: flex;
      position: relative;
      height: auto;
      flex-direction: row;
      opacity: 0;
      transition: opacity .5s ease;

      @media only screen and (max-width: $grid-break-md) {
        display: none;
      }

      &.visible {
        opacity: 1;
      }

      .appPreview {
        height: 70vh;

        &:nth-child(1) {
          z-index: 100;
          transition: transform .3s ease;
          transition-delay: .5s;

        }

        &:nth-child(2) {
          margin-top: 10%;
          margin-left: -10%;
        }
      }
    }
  }

  .aboutContainer {
    .sectionCard {
      padding: 0 16px;

      .sectionHeader {
        color: map-get($colors, primary);
        font-family: "Source Sans Pro", sans-serif;
        font-size: 16px;
        font-weight: 400;
        padding: 0;
        margin: 0;

        .fat {
          color: map-get($colors, primary);
          font-family: "Source Sans Pro", sans-serif;
          font-weight: 700;
        }
      }

      .sectionIcon {
        margin-bottom: 15px;
      }

      .sectionMainText {
        font-family: "Source Sans Pro", sans-serif;
        letter-spacing: 0;
        font-size: 23px;
        font-weight: 400;
        color: #525459;
        line-height: 31px;
        margin: 5px 0 0 0
      }

      .sectionSubText {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
        line-height: 23px;
        font-weight: 400;
        padding: 15px 0;
        color: #7e868e;
        margin: 0;
      }

      .sectionList {
        padding: 0 0 0 20px;

        li {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 16px;
          line-height: 23px;
          font-weight: 400;
          color: #7e868e;
          margin: 0;
        }
      }
    }
  }

  .partnerContainer {
    .partners {
      justify-content: center;
      .partnerTile {
        position: relative;
        margin: 8px;
        border-radius: 3px;
        -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);

        &:before {
          content: "";
          display: block;
          padding-top: 100%; /* initial ratio of 1:1*/
          z-index: 800;
        }

        .partnerLogo {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          background-size: 80%;
          background-position: center center;
          background-repeat: no-repeat;
        }
      }
    }
  }

  .videoContainer {
    position: relative;
    padding-top: 56.25%;

    .video {
      width: 100%;
      height: 100%;
      display: block;
      margin: 0;
      top: 0;
      left: 0;
      position: absolute;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
}

