@import '../../colors';

.teamContainer {
  .member {
    display: flex;
    padding: 32px;
    flex-direction: column;
    .imageContainer {
      overflow: hidden;
      width: 100%;
      height: 380px;
      .image {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
        transition: transform .3s ease;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
    .name {
      font-size: 23px;
      text-align: left;
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 400;
      font-style: normal;
      margin: 0;
    }
    .role {
      font-size: 13px;
      color: #1abc9c;
      text-align: left;
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      font-style: normal;
      margin: 0;
    }
    .story {

    }
    .linkedin {
      .link {
        font-size: 1.2rem;
        color: map-get($colors, primary);
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 800;
        font-stretch: normal;
        text-decoration: none;
      }
    }
  }
}

