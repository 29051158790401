@import '../../colors';

$desktopNavigatorHeight: 80px;
$mobileNavigatorHeight: 60px;

$grid-break-md: 640px !default;

.navigationContainer {
  width: 100%;
  position: absolute;
  z-index: 800;
  left: 0;
  background-color: transparent;
  transition: all .3s ease;
  transform: translate3d(0,0,0);
  &.visible {
    position: fixed;
    top: 0px;
    background-color: map-get($colors, darkgray);
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  }

  @media only screen and (max-width: $grid-break-md) {
    margin-top: 0;
    background-color: map-get($colors, darkgray);
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  }

  .navigationWrapper {
    @media only screen and (max-width: $grid-break-md) {
      height: $mobileNavigatorHeight;
    }

    @media only screen and (min-width: $grid-break-md) {
      height: $desktopNavigatorHeight;
    }

    max-width: 1200px;
    width: 100%;

    margin: auto;
    display: flex;
    flex: 1 1 100%;
    flex-direction: row;
    justify-content: space-between;
    .logoContainer {
      display: flex;
      align-items: center;
      padding: 0 0 0 16px;
      flex: 0 0 auto;
      .logo {
        @media only screen and (max-width: $grid-break-md) {
          width: 150px;
        }

        @media only screen and (min-width: $grid-break-md) {
          width: 150px;
        }
        cursor: pointer;
      }
    }

    .desktop {
      @media only screen and (max-width: $grid-break-md) {
        display: none;
      }

      @media only screen and (min-width: $grid-break-md) {
        display: flex;
      }

      flex: 1 1 auto;
      flex-grow: 1;
      list-style: none;
      padding: 0;
      margin: 0;
      justify-content: flex-end;
      .item {
        padding: 0 8px;
        display: inline-flex;
        position: relative;
        cursor: pointer;
        align-items: center;
        font-family: "Source Sans Pro", sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        transition: color .2s linear;
        height: 100%;
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 4px;
          left: 0;
          bottom: 0;
          background-color: transparent;
          transition: background-color .4s linear;
        }

        &:hover, &.active {
          color: map-get($colors, primary);
          opacity: 1;

          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 4px;
            left: 0;
            bottom: 0;
            background-color: map-get($colors, primary);
          }
        }
      }
    }

    .operatorButton {
      @media only screen and (max-width: $grid-break-md) {
        display: none;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase !important;
    }

    .countryContainer {
      display: flex;
      align-items: center;
      margin: 0 0 0 auto;
      position: relative;
      width: 50px;
      justify-content: space-between;
      padding: 0 8px;
      .currentCountry {
        width: 30px;
        height: 15px;
        background-size: contain;
        background-repeat: no-repeat;
        margin: 0 8px 0 0;
        cursor: pointer;
      }
      .countrySelector {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #fff;
        cursor: pointer;
        transition: all .3s ease;
        &:hover {
          border-top: 8px solid map-get($colors, primary);
        }
        &.active {
          transform: rotate(-180deg);
        }
      }
      .possibleCountries {
        display: flex;
        width: 80px;
        background-color: #fff;
        flex-direction: column;
        max-height: 0;
        overflow: hidden;
        position: absolute;
        transition: all .3s ease;
        -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        z-index: 700;
        @media only screen and (max-width: $grid-break-md) {
          top: 60px;
        }

        @media only screen and (min-width: $grid-break-md) {
          top: 80px;
        }

        &.visible {
          max-height: 100px;
        }
        .possibleCountry {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 8px;
          cursor: pointer;
          &:hover {
            background-color: map-get($coolGray, c1);
          }
          .flag {
            width: 30px;
            height: 15px;
          }
        }
      }
    }

    .toggle {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: map-get($colors, primary);
      @media only screen and (max-width: $grid-break-md) {
        display: flex;
      }

      @media only screen and (min-width: $grid-break-md) {
        display: none;
      }
    }
  }
  .mobile {
    flex: 1 1 auto;
    flex-grow: 1;
    list-style: none;
    margin: 0;
    flex-direction: column;
    max-height: 0;
    overflow: hidden;
    background-color: map-get($coolGray, c1);
    padding: 0 32px;
    transition: all .2s linear;

    &.open {
      max-height: 500px;
    }
    @media only screen and (max-width: $grid-break-md) {
      display: flex;
    }

    @media only screen and (min-width: $grid-break-md) {
      display: none;
    }

    .item {
      padding: 24px 16px;
      display: flex;
      position: relative;
      text-transform: uppercase;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      font-weight: 500;
      color: #000;
      transition: color .2s linear;
      text-decoration: underline;
      border-bottom: 1px solid map-get($coolGray, c3);
      &.active {
        color: map-get($colors, primary);
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
