@import '../../colors';

.switchContainer {
  flex: 0 0 auto;
  display: flex;
  height: 24px;
  width: 40px;
  border-radius: 20px;
  background-color: map-get($coolGray, c1);
  align-items: center;
  border: 1px solid map-get($coolGray, c3);
  transition: background-color .2s ease;
  position: relative;
  &.disabled {
    background-color: map-get($colors, warning);
    cursor: not-allowed;
  }

  &.active {
    background-color: map-get($colors, primary);
  }
  .thumb {
    width: 22px;
    height: 22px;
    border-radius: 11px;
    background-color: #fff;
    position: absolute;
    top: 1px;
    left: 2px;
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: left .2s ease;
    &.active, &.disabled {
      left: 16px;
    }
    &.disabled {
      cursor: not-allowed;
    }
  }
}
