@import '../../colors';

.cookieConsentContainer {
  .cookieHeader {
    padding: 32px 0 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .cookieTitle {
      letter-spacing: 0;
      font-family: "Source Sans Pro", sans-serif;
      font-size: 23px;
      line-height: 31px;
      font-weight: 400;
      margin: 0;
    }
    .cookieDescription {
      letter-spacing: 0;
      font-family: "Source Sans Pro", sans-serif;
      font-size: 14px;
      line-height: 31px;
      font-weight: 400;
      margin: 0;
    }
  }
  .cookieConsentForm {
    padding: 32px 0;
    .cookieContainer {
      display: flex;
      flex-direction: column;
      padding: 0 8px;
      .toggleContainer {
        flex: 0 0 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        .cookieTitle {
          margin: 0;
          padding-left: 8px;
          letter-spacing: 0;
          font-family: "Source Sans Pro", sans-serif;
          font-size: 23px;
          line-height: 31px;
          font-weight: 400;
        }
      }
      .descriptionContainer {
        flex: 1 1 auto;
        p {
          letter-spacing: 0;
          font-family: "Source Sans Pro", sans-serif;
          font-size: 14px;
          line-height: 31px;
          font-weight: 400;
        }
      }
    }
  }
  .cookieConsentButtons {
    padding: 0 0 32px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
