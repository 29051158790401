@import '../../colors';

.shareContainer {
  .beatLoader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

