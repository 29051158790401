@import '../../colors';

.toastWrapper {
  position: fixed;
  width: 100%;
  z-index: 800;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-name: fadeInToast;
  animation-duration: .2s;
  animation-timing-function: ease;
  &.hide {
    animation-name: fadeOutToast;
    animation-duration: .2s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }

  @keyframes fadeInToast {
    from {
      background-color: rgba(0, 0, 0, 0);
    }
    to {
      background-color: rgba(0, 0, 0, .6);
    }
  }

  @keyframes fadeOutToast {
    from {
      background-color: rgba(0, 0, 0, .6);

    }
    to {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  .toastContainer {
    padding: 16px 16px 16px 32px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    .toastContent {
      display: flex;
      flex-direction: row;
      .toastMessage {
        .toastTitle {
          font-family: "Source Sans Pro", sans-serif;
          letter-spacing: 0;
          font-size: 1.4rem;
          font-weight: 400;
          color: #fff;
          margin: 0;
        }
        .toastDescription {
          font-family: "Source Sans Pro", sans-serif;
          letter-spacing: 0;
          font-size: 1rem;
          font-weight: 400;
          color: #fff;
          margin: 0;
        }
      }
      .close {
        flex: 0 0 auto;
        display: flex;
        color: #fff;
        text-decoration: underline;
        align-items: center;
        justify-content: center;
        padding: 16px;
      }
    }
  }
}
