$colors: (
        primary: #5cb89d,
        main: #0A1014,
        darkgray: #17191c,
        warning: #ff6565,
);

$coolGray: (
        c1: #f9f9fe,
        c2: #f1f1f6,
        c3: #d9d9de,
        c4: #d1d1d8,
);

:export {
  primary: map-get($colors, primary);
  darkgray: #17191c;
  warning: #ff6565;
  main: #0A1014;

  c1: map-get($coolGray, c1);
  c2: map-get($coolGray, c2);
  c3: map-get($coolGray, c3);
  c4: map-get($coolGray, c4);
}
