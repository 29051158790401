// SIMPLE GRID - SASS/SCSS
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600');
@import './colors';
@import './grid';

// fonts
$font-family: 'Source Sans Pro', sans-serif;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-heavy: 700;
$font-height: 1.5;

// colors
$dark-grey: #585858;
$dark-gray: #585858; // for the Americans

// universal
html {
  background-color: #fff;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  font-size: 100%;
  background-color: #fff;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

* {
  font-family: $font-family;
  color: $dark-grey;
  line-height: $font-height;
}

.cookieContainer {
  position: relative;
  flex-direction: row;
  display: block;
}

// typography

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8;
}

.font-light {
  font-weight: $font-weight-light;
}

.font-regular {
  font-weight: $font-weight-regular;
}

.font-heavy {
  font-weight: $font-weight-heavy;
}

// utility

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.justify {
  text-align: justify;
}

h1, h2, h3, h4, h5, h6 {
  color: #262626;
}

.formGroup {
  display: flex;
  &.flex-row {
    flex-direction: row;
  }
  &.flex-column {
    flex-direction: column;
  }
}

.input {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  label {
    font-weight: 600;
    font-size: 8pt;
    padding: 0 4px;
    margin: 16px 0 8px 0;
  }
}

.inputField {
  flex: 1 1 auto;
  display: flex;
  border: 1px solid map-get($coolGray, c2);
  padding: 0 16px;
  background-color: map-get($coolGray, c1);
  height: auto;
  margin: 4px;
  align-items: center;
  &.error {
    border: 1px solid #fc642d;
    background-color: #fff8f6;
  }
  &.success {
    border: 1px solid map-get($colors, primary);
    background-color: #fff8f6;
  }
  label {
    flex: 1 1 auto;
  }
  input {
    flex: 1 1 auto;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
  }
  textarea {
    display: flex;
    flex: 1 1 auto;
    border: none;
    outline: none;
    background-color: transparent;
    resize: none;
    align-items: center;
    justify-content: center;
    padding: 8px 0 0 0;
  }
}

.readMore {
  font-size: .8rem;
  color: #444;
  font-weight: 400;
  cursor: pointer;
  transition: all .2s linear;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
    transform: translate(0, -5px);
    color: map-get($colors, primary);
  }
}

.link {
  text-decoration: underline;
  cursor: pointer;
  transition: all .3s ease;
  &:hover {
    color: map-get($colors, primary);
  }
}

