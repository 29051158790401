@import '../../colors';

.faqContainer {
  .questionsContainer {
    .qaContainer {
      display: flex;
      flex-direction: column;
      border-top: 1px solid map-get($coolGray, c2);
      border-left: 1px solid map-get($coolGray, c2);
      border-right: 1px solid map-get($coolGray, c2);
      &:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        overflow: hidden;
      }
      &:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        overflow: hidden;
        border-bottom: 1px solid map-get($coolGray, c2);
      }
      .questionContainer {
        display: flex;
        align-items: center;
        background-color: map-get($coolGray, c1);
        padding: 16px;
        cursor: pointer;
        .question {
          flex: 1 1 auto;
          // color: map-get($colors, main);
          letter-spacing: 0;
          font-family: "Source Sans Pro", sans-serif;
          font-size: 23px;
          line-height: 31px;
          font-weight: 400;
          margin: 0;
        }
        .icon {
          flex: 0 0 30px;
          width: 30px;
          height: 30px;
          display: flex;
          transition: transform .3s ease;
          align-items: center;
          justify-content: center;
          &.open {
            transform: rotate(-180deg);
          }
        }
      }
      .answer {
        max-height: 0;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
        color: #7e868e;
        overflow: hidden;
        transition: max-height .3s ease;
        padding: 0 16px;
        &.visible {
          max-height: 500px;
        }
      }
    }
  }
}

